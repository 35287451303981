<template>
    <div class="body-container">
        <BannerSection title="Prestamos con garantia de auto en Puebla" />
        <PerksComponent></PerksComponent>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <p class="mb-0">
                        En Puebla, como en muchas otras partes del país, la búsqueda de soluciones financieras flexibles
                        se ha vuelto una constante. Muchas personas necesitan acceder a recursos económicos de manera
                        rápida para atender emergencias o aprovechar oportunidades sin esperar largos trámites o
                        aprobaciones bancarias.
                    </p>
                    <p>
                        Ibancar ofrece una solución práctica y accesible a través de sus <b>préstamos en Puebla</b> con
                        garantía de auto. De este modo, permite a los clientes obtener recursos económicos rápidamente,
                        mientras siguen disfrutando del uso de su vehículo.
                    </p>
                    <p>
                        Este servicio no solo es rápido y eficiente, sino que también está disponible para aquellos que
                        pueden estar en el Buró de Crédito, proporcionando una oportunidad financiera donde otras
                        puertas podrían estar cerradas.
                    </p>
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">¿Qué son los préstamos con garantía de auto?
                        </h2>
                    </span>

                    <p>
                        Es una solución financiera donde puedes obtener recursos económicos rápidos usando tu vehículo
                        como garantía, sin necesidad de venderlo o dejar de usarlo.
                    </p>
                    <p>A diferencia de los <b>empeños en Puebla</b>, donde frecuentemente debes dejar el objeto empeñado
                        en depósito, con Ibancar tu auto permanece en tu poder y bajo tu control mientras devuelves el
                        préstamo. Esta modalidad te permite mantener la propiedad y el uso diario de tu vehículo,
                        proporcionando tranquilidad y continuidad en tus actividades cotidianas.
                    </p>
                    <p>
                        Este tipo de préstamo es ideal para quienes necesitan liquidez inmediata, pero desean seguir
                        disfrutando de la libertad que ofrece tener un auto. Además, Ibancar facilita el proceso a
                        través de una solicitud 100% en línea, lo que simplifica aún más el acceso a los fondos
                        necesarios sin las complicaciones de los trámites tradicionales.
                    </p>
                    <br>
                    <span class="bold-text">
                        <h2 style="font-size: 20px;">Beneficios de los préstamos de Ibancars</h2>
                    </span>
                    <p>
                        Los préstamos con garantía de auto de Ibancar ofrecen múltiples beneficios que los hacen una
                        opción atractiva para los residentes del estado de Puebla:
                    </p>
                    <br>
                    <ul>
                        <li>
                            <p>
                                <b>Rapidez en la obtención del préstamo:</b> puedes tener el dinero disponible en hasta 48 horas, lo que es ideal para situaciones
                                de urgencia o cuando necesitas actuar rápidamente.
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Continúa utilizando tu auto:</b> mientras estás pagando el préstamo, sigues teniendo el control total de tu vehículo. Esto te permite mantener tu movilidad y estilo de vida sin interrupciones.
                            </p>
                        </li>
                        <li>
                            <p>
                                <b><router-link to="/" class="mb-0 bold-text">Préstamos sin importar buró de crédito:</router-link></b> no necesitas un historial crediticio limpio para calificar, lo que facilita el acceso a los recursos financieros a una mayor cantidad de personas, incluso aquellas con dificultades crediticias previas.
                            </p>
                        </li>
                    </ul>
                    <br>
                </div>
            </div>
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <p>Este proceso está diseñado para ser rápido y eficiente, asegurando que puedas continuar con tu vida diaria con la menor interrupción posible mientras gestionas tus necesidades financieras.</p>
                    <p>
                        Los <b>préstamos con garantía de auto en Puebla</b> de Ibancar, ofrecen una solución financiera eficaz y accesible para quienes necesitan liquidez sin demoras ni trámites engorrosos. 
                    </p>
                    <p>
                        La rapidez del servicio, la posibilidad de seguir utilizando tu vehículo y la flexibilidad en los requisitos crediticios hacen de Ibancar una opción destacada en tu localización. Si te encuentras en una situación donde necesitas fondos con urgencia, considera esta oferta como una alternativa viable.
                    </p>
                    <br>
                    <br>
                </div>

            </div>

        </div>

        <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
            <div class="ibancar-election">
                <p>¿Por qué elegir Ibancar?</p>
            </div>
            <BeneficiosIbancarCarousel />
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">¿Cómo solicitar un préstamo en Ibancar desde Puebla?</h2>
                    </span>
                    <p>Solicitar un préstamo en Ibancar es un proceso sencillo y completamente en línea. Te explicamos cómo hacerlo:</p>
                    <br>
                    <ul>
                        <li>
                            <p>
                                Accede a la plataforma
                            </p>
                        </li>
                        <li>
                            <p>
                                Completa la solicitud                                
                            </p>
                        </li>
                        <li>
                            <p>
                                Prepara tu documentación. Por ejemplo: INE, comprobante de ingresos y de domicilio, y documentación del vehículo.
                            </p>
                        </li>
                        <li>
                            <p>
                                Envía tu solicitud
                            </p>
                        </li>
                        <li>
                            <p>
                                Espera la aprobación
                            </p>
                        </li>
                    </ul>
                    <br>
                </div>

            </div>

        </div>
     
        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'PrestamosDeDineroRapido',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        SolicitaloBannerS2,
        PerksComponent
    },
    setup() {
        useMeta(
            {
                title: 'Préstamos con garantía de auto en Puebla',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: 'Obtén liquidez rápida con préstamos con garantía de auto en Puebla. Sigue usando tu vehículo mientras accedes a financiamiento flexible, incluso si estás en Buró de Crédito. Solicita en línea y recibe tu dinero en 48 horas. ¡Fácil, seguro y sin complicaciones!'
                    },
                    {
                        name: 'keywords',
                        content: 'Prestamos en Puebla - empeños en puebla'
                    }
                ],
                link: [
                    // { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
                    { href: 'https://ibancar.mx/prestamos-garantia-auto-puebla', rel: 'alternate', hreflang: 'es-MX' }
                ]
            }
        )
    },

    data() {
        return {
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró.',
                }

            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.image-container {
    text-align: center;
    margin: 3rem 0rem;

    img {
        max-height: 400px;
    }
}

.body-container {
    margin-top: 4.5rem;
    min-height: calc(100vh - 115px);
}

@include media-breakpoint-up(md) {
    .body-container {
        margin-top: 8.5rem;
    }
}

.ibancar-election {
    margin: 1rem 2rem 0rem 2rem;

    p {
        color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 1.3rem;
        font-style: normal;
        line-height: normal;
        margin: 1.5rem 0rem;
    }
}

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-align: justify;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}


.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }

    .section-container {
        margin-top: 1.5rem;
    }
}
</style>